.page-node-349:not(.page-node-edit) {
  #content {
    #zone-content {
      min-height: initial;
      color: #fff;
      font-weight: 200;
      text-align: center;
      padding: 0 2em;
    }
  }

  #region-content {
    background-color: #33AC41;

    .title {
      font-weight: 200;
      margin: 2em 0 1em 0;
    }
  }
}

.infograf-closing-text {
  text-align: center;
  text-transform: uppercase;
  margin: 3em 0;
  color: #999;
  font-weight: 600;
  padding: 0 10px;

  .green {
    color: green;
  }

  .orange {
    color: orange;
  }

  .blue {
    color: blue;
  }

  .red {
    color: red;
  }

  span {
    font-size: x-large;
  }
}